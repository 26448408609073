// Include functions (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// Custom Code below
$purple: $indigo-400;
$lime: #4bc81e;
$maroon: maroon;

// Create a new custom theme map
$custom-theme-colors: (
    purple: $purple,
    lime: $lime,
    maroon: $maroon,
);

// Merge the map into the default theme-colors map
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$nav-pills-link-active-bg: $purple;

@import "~bootstrap/scss/bootstrap";
